import React from 'react';
import dayjs from 'dayjs';
import { MdLocationOn } from 'react-icons/md';

import {
  Content,
  DateWrapper,
  DayMonth,
  Year,
  Description,
  List,
  Location,
  Icon,
  Time,
  Title,
} from './EventList.styled';

interface Props {
  className?: string;
  title: string;
  description?: string;
  date: string;
  end_date?: string;
  location?: string;
}

/**
 * Used to display past events
 * @param {string} title
 * @param {string} descripton (Optional)
 * @param {string} date Date string for when the event will take place
 * @param {string} end_date Date string for when the event will end (Optional)
 * @param {string} location (Optional)
 */
function EventList({
  className,
  title,
  description,
  date,
  end_date,
  location,
}: Props): JSX.Element {
  const parsedTime = dayjs(date);
  const parsedEndTime = dayjs(end_date);

  return (
    <List className={className || ''}>
      {parsedEndTime.isValid() ? (
        <DateWrapper>
          <DayMonth className="start-date">
            {parsedTime.format('D MMM')}
          </DayMonth>
          <DayMonth className="end-date">
            - {parsedEndTime.format('D MMM')}
          </DayMonth>
          <Year className="date-bit">{parsedTime.format('YYYY')}</Year>
        </DateWrapper>
      ) : (
        <DateWrapper>
          <DayMonth className="date-bit">{parsedTime.format('D MMM')}</DayMonth>
          <Year className="date-bit">{parsedTime.format('YYYY')}</Year>
        </DateWrapper>
      )}
      <Content>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Time>{parsedTime.format('ddd HH:mm')}</Time>
        {location && (
          <Location>
            <Icon>
              <MdLocationOn />
            </Icon>
            <span>{location}</span>
          </Location>
        )}
      </Content>
    </List>
  );
}

export default EventList;
