import React from 'react';
import dayjs from 'dayjs';
import { Typography } from 'amphitheatre';
import { graphql, StaticQuery } from 'gatsby';

import Layout from 'src/Layout';

import Container from 'src/Components/ContentContainer';
import EventList from 'src/Components/EventList';
import SEO from 'src/Components/SEO';

import * as Base from 'src/page-styles/base.styled';

const { Header } = Typography;

interface Event {
  node: {
    frontmatter: {
      title: string;
      description: string;
      date: string;
      end_date: string;
      location: string;
    };
  };
}

interface Props {
  data: {
    allMarkdownRemark: {
      edges: Array<Event>;
    };
  };
}

function NoUpcoming() {
  return <Base.Paragraph>There are no upcoming events.</Base.Paragraph>;
}

/**
 * Returns a JSX element to indicate that there are no past events
 * (Although, I do not know how this is necessarily possible for a production site)
 */
function NoPast() {
  return <Base.Paragraph>There are no past events.</Base.Paragraph>;
}

const Events: React.FunctionComponent<Props> = ({ data }: Props) => {
  const { allMarkdownRemark } = data;
  const { edges: events } = allMarkdownRemark;

  const upcomingEvents: Event[] = [];
  const pastEvents: Event[] = [];

  events.forEach((event) => {
    const eventDate = dayjs(event.node.frontmatter.date);
    const now = dayjs();

    if (eventDate.isAfter(now)) {
      upcomingEvents.push(event);
    } else {
      pastEvents.push(event);
    }
  });

  // Sorts past events in ascending order.
  pastEvents.sort((eventA, eventB) => {
    return !dayjs(eventA.node.frontmatter.date).isAfter(
      dayjs(eventB.node.frontmatter.date),
    )
      ? 1
      : -1;
  });

  let upcomingEventsElms = <NoUpcoming />;

  if (upcomingEvents?.length) {
    upcomingEventsElms = (
      <>
        {upcomingEvents.map((event, index) => {
          return (
            <EventList key={`event-${index}`} {...event.node.frontmatter} />
          );
        })}
      </>
    );
  }

  return (
    <>
      <SEO
        title="Events | Saber Astronautics"
        description="Meet our team, learn more about space and what we do. Find an event we'll be attending."
        url="https://www.saberastro.com/events"
      />
      <Layout>
        <Container.Dark>
          <Base.MenuGap />
          <Base.Heading>SABER EVENTS</Base.Heading>
          <Base.Text>
            <Header>
              <Base.Yellow>Upcoming</Base.Yellow>
            </Header>
            {upcomingEventsElms}
            <Header>
              <Base.Yellow>Past</Base.Yellow>
            </Header>
            {pastEvents ? (
              pastEvents.map((event, index) => {
                return (
                  <EventList
                    key={`past-event-${index}`}
                    {...event.node.frontmatter}
                  />
                );
              })
            ) : (
              <NoPast />
            )}
          </Base.Text>
        </Container.Dark>
      </Layout>
    </>
  );
};

export default (): JSX.Element => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "events" } } }
          sort: { fields: frontmatter___date, order: ASC }
        ) {
          edges {
            node {
              frontmatter {
                title
                date
                end_date
                description
                location
              }
            }
          }
        }
      }
    `}
    render={(data) => <Events data={data} />}
  />
);
